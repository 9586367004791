.about_bg_wrapper {
    position: relative;
    padding: 80px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background: var(--primary-color-50);
    /* background-image: url('../../assets/images/contact/contact_1.png'); */
    /* z-index: -1; */
}

/* .about_bg_wrapper::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, .5);
} */

/* Mission and Vision */
.about-us{
    margin-bottom: 50px;
}

/* .image-hover-style-3 {
    overflow: hidden;
    position: relative;
    cursor: pointer;
} */

/* .feature-box-wrap {
    position: relative;
    width: 100%;
} */

.no-gutters>.col, .no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0;
}

.about__us__mv .cover-background {
    position: relative !important;
    background-size: cover !important;
    overflow: hidden !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.about__us__mv .small-screen {
    /* display: block; */
    height: 400px;
}

.about__us__mv .arrow-top, .about__us__mv .arrow-bottom {
    position: relative;
}

.about__us__mv .sm-height-300px {
    height: 300px;
}

.about__us__mv .arrow-top::after {
    top: -29px;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(245, 245, 245, 0);
    border-bottom-color: #fff;
    border-width: 15px;
    margin-left: -15px;
    z-index: 9;
}

.about__us__mv .arrow-bottom::before {
    bottom: -29px;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(245, 245, 245, 0);
    border-top-color: #ffffff;
    border-width: 15px;
    margin-left: -15px;
    z-index: 9;
}

.about__us__mv .padding-eighteen-lr {
    padding-left: 10%;
    padding-right: 10%;
    border: 1px solid #eee;
}

.mv_image_1 {
    background-image: url('../../assets/images/features/mission.jpg');
}

.mv_image_2 {
    background-image: url('../../assets/images/features/mission.jpg');
}

.mv_image_3 {
    background-image: url('../../assets/images/features/mission.jpg');
}

/* About us Mission and Vision new */

.about_us__mv-new{
    background: var(--primary-color-50);
    padding: 50px 0px;
}
.ab_mv_row{
    margin-bottom: 30px;
}
.mv_col{
    display: flex;
    justify-content: center;
    align-items: center;
}
.about_us_mv_title-new{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px;
    color: var(--primary-color);
}

.about_us_mv_des-new{
    font-size: 16px;
}

.about_us_mv_des-new ul{
    padding-left: 0px;
}

.about_us_mv_des-new ul li {
    background-image: url(../../assets/images/product-bullet.png);
    background-repeat: no-repeat;
    padding-right: 20px;
    background-position: 0 5px;
    padding-left: 23px;
    padding-bottom: 5px;
}

@media (max-width: 576px) {
    .about_us_mv_title-new , .about_us_mv_des-new{
        text-align: center;
    }
}