.ourproducts_main-wrapper {
    padding-bottom: 50px;
}

.ourproducts_title {
    font-size: 45px;
    margin-bottom: 45px;
    text-align: center;
}

.ourproducts_title::after {
    content: "";
    border-bottom: 3px solid var(--primary-color);
    width: 21%;
    display: block;
    padding-top: 8px;
    margin: auto;
}

.product_bg_wrapper {
    position: relative;
    padding: 80px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background: var(--primary-color-50);
    /* background-image: url('../../assets/images/contact/contact_1.png'); */
    /* z-index: -1; */
}

/* .product_bg_wrapper::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, .5);
} */

/* .products__des-wrapper{
    row-gap: 30px;
}

.products__des-wrapper .products_inner_box {
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0px 0px 4px 0px #c1c1c1;
}

.products__des-wrapper .products_inner_box .product__image {
    position: relative;
    line-height: 24px;
}

.products__des-wrapper .products_inner_box .product__image img {
    position: relative;
    width: 100%;
    display: block;
    transition: transform .5s ease;
    overflow: hidden;
}

.products__des-wrapper .products_inner_box:hover .product__image img {
    transform: scale(1.1);
}

.products__des-wrapper .products_inner_box .product__image .overlay__box {
    padding: 10px 15px 10px 15px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.products__des-wrapper .products_inner_box .product__image .overlay__box .content {
    width: 100%;
    padding: 0;
}

.products__des-wrapper .products_inner_box .product__image .overlay__box .content h3 {
    position: relative;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 16px;
    margin-bottom: 0 !important;
}

.clearfix:after {
    display: block;
    clear: both;
    content: "";
} */