.career_form__filled-wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.career_form__filled-wrapper .contact--ctm__input{
    padding: 13px 15px;
    background-color: var(--primary-color-50);
    border-width: 0px 0px 0px 0px;
    border-radius: 4px 4px 4px 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    width: 100%;
}

.career_form__filled-wrapper .contact--ctm__input:focus-visible {
    border: none !important;
    outline: none !important;
    box-shadow: 0 0 8px rgba(254, 138, 15, 0.5); /* Adding a different box-shadow on focus */
}

.career_form__filled-wrapper .contact--ctm__select{
    height: 50px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}
.apply_now_btn{
    padding: 10px;
    box-shadow: none;
    /* background: transparent; */
}

.career__wrapper .accordion-button:not(.collapsed) ,.career__wrapper .accordion-button{
    color: var(--white-color);
    background: var(--primary-color);
    box-shadow: none !important;
}

.career__wrapper .accordion-button::after{
    background-image: url('../../assets/images/download.svg') !important;
}

.career__wrapper .apply-now_btn{
    background: var(--primary-color) !important;
    border: var(--primary-color) !important;
    padding: 8px 30px;
}

.career__wrapper .accordion-item{
    border: 1px solid var(--primary-color);
}
