.hrwires_main-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
}

.hrwires_title {
    font-size: 45px;
    margin-bottom: 120px;
    text-align: center;
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: var(--primary-color);
}

/* .hrwires_title::after {
    content: "";
    border-bottom: 3px solid var(--primary-color);
    width: 22%;
    display: block;
    padding-top: 8px;
    margin: auto;
} */

.hrwires_main-wrapper .row {
    row-gap: 150px;
}

.house_wires_img {
    position: relative;
}

.house_wires_img--wrapper {
    width: 100%;
    height: 136px;
    border-radius: 16px;
    background: var(--primary-color);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.house_wires_img {
    position: absolute;
    top: -60%;
    width: 85%;
    transform: scale(1);
    transform-origin: center;
    transition: transform 0.5s ease;
}

.house_wires_img--wrapper:hover .house_wires_img {
    transform: scale(1.1);
    /* transform-origin: bottom; */
}

/* Cables */
.aicables_main-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
}

.aicables_title {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 180px;
    text-align: center;
}

.aicables_title::after {
    content: "";
    border-bottom: 3px solid var(--primary-color);
    width: 55%;
    display: block;
    padding-top: 8px;
    margin: auto;
}

.aicables_row {
    row-gap: 156px;
    justify-content: center;
}

.aicables_img--wrapper {
    width: 100%;
    height: 160px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: var(--primary-color);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow: hidden;  */
}

.aicables__img {
    position: absolute;
    bottom: 0;
    height: 170%;
    transform: scale(1);
    transform-origin: bottom;
    transition: transform 0.5s ease;
}

.aicables_img--wrapper:hover .aicables__img {
    transform: scale(1.1);
    /* transform-origin: bottom; */
}

.aicables__product-name {
    font-size: 16px;
    text-align: center;
    margin: 8px 0px;
    font-weight: 500;
}

/* Cables */
.products_cables__wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
}

.cables__main--img {
    height: 80%;
}

.cables__main--title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 8px;
}

/* Responsive */
@media (max-width: 575.98px) {
    .hrwires_main-wrapper {
        padding-bottom: 25px;
    }

    .hrwires_main-wrapper .row {
        row-gap: 100px;
    }

    .hrwires_title,
    .aicables_title {
        font-size: 30px;
    }

    .house_wires_img {
        width: 60%;
        top: -42%;
    }

    /* Cables */
    .aicables_main-wrapper {
        padding-bottom: 25px;
    }

    .aicables_title {
        margin-bottom: 150px;
    }

    .aicables_title::after {
        width: 92%;
    }

    .aicables_row {
        row-gap: 140px;
    }

    /* End cables */
    .cables__main--title{
        font-size: 25px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .hrwires_main-wrapper {
        padding-bottom: 30px;
    }

    .hrwires_main-wrapper .row {
        row-gap: 130px;
    }

    .hrwires_title {
        font-size: 35px;
    }

    .house_wires_img {
        top: -42%;
    }

    /* Cables */
    .aicables_main-wrapper {
        padding-bottom: 30px;
    }

    .aicables_title {
        font-size: 35px;
        margin-bottom: 150px;
    }

    .aicables_title::after {
        width: 90%;
    }

    .aicables_row {
        row-gap: 140px;
    }

    /* End Cables */
    .cables__main--title{
        font-size: 25px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .hrwires_main-wrapper {
        padding-bottom: 40px;
    }

    .hrwires_title {
        font-size: 40px;
    }

    /* Cables */
    .aicables_main-wrapper {
        padding-bottom: 40px;
    }

    .aicables_title {
        font-size: 40px;
        /* margin-bottom: 150px; */
    }

    .aicables_title::after {
        width: 92%;
    }

    /* End Cables */
    .cables__main--title{
        font-size: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .hrwires_title {
        font-size: 43px;
        margin-bottom: 100px;
    }

    .house_wires_img--wrapper {
        height: 120px;
    }

    .house_wires_img {
        top: -39%;
    }

    /* Cables */
    .aicables_title {
        font-size: 43px;
    }

    .aicables_title::after {
        width: 74%;
    }

    /* End Cables */
    .cables__main--title{
        font-size: 35px;
    }
}

/* End Responsive */