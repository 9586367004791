.breadcrumb__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    /* z-index: 1; */
}

.breadcrumb__wrapper ol {
    margin-bottom: 0px;
}

.breadcrum_title {
    color: var(--white-color);
    font-size: 45px;
    margin-bottom: 30px;
    font-weight: 600;
    text-transform: uppercase;
}

.breadcrumb__wrapper_or .breadcrum_title {
    color: var(--primary-color);
}

.breadcrumb__wrapper_or .breadcrumb a,
.breadcrumb__wrapper_or .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb__wrapper_or .breadcrumb-item.active {
    color: var(--black-color);
}

.breadcrumb__wrapper .breadcrumb .breadcrumb-item a{
    cursor: pointer;
}

.breadcrumb__wrapper .breadcrumb-item.active {
    text-underline-offset: 12px;
    text-decoration: underline;
}

@media (max-width: 576px) {
    .breadcrum_title {
        font-size: 30px;
        margin-bottom: 20px;
    }
}

/* Responsive styles for screens between 576px and 767.98px */
@media (min-width: 576px) and (max-width: 767.98px) {
    .breadcrum_title {
        font-size: 35px;
        margin-bottom: 25px;
    }
}

/* Responsive styles for screens between 768px and 991.98px */
@media (min-width: 768px) and (max-width: 991.98px) {
    .breadcrum_title {
        font-size: 40px;
        margin-bottom: 28px;
    }
}

/* Responsive styles for screens between 992px and 1199.98px */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .breadcrum_title {
        font-size: 43px;
    }
}