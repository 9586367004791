.counter_main-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: var(--primary-color-50);
    color: var(--black-color);
    border-radius: 12px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.counter__wrapper {
    font-size: 40px;
    font-family: var(--font-family);
    font-weight: bold;
    padding-bottom: 6px;
}

.counter_col-wrapper {
    text-align: center;
}

.counter_title {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 6px;
    color: var(--primary-color);
}

@media (max-width: 576px) {
    .counter_main-wrapper {
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .counter__wrapper {
        font-size: 25px;
    }

    .counter_title {
        font-size: 16px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .counter_main-wrapper {
        padding-top: 40px;
        padding-bottom: 40px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .counter__wrapper {
        font-size: 30px;
    }

    .counter_title {
        font-size: 18px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .counter_main-wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .counter__wrapper {
        font-size: 35px;
    }

    .counter_title {
        font-size: 18px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .counter_main-wrapper {
        padding: 30px;
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .counter_title {
        font-size: 18px;
    }
}