.fcsc__wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
}

.fcsc__img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 5px solid var(--primary-color);
    border-radius: 30px;
}

.fcsc__img-wrapper img {
    height: 80%;
}

/* Tabs */
.fc__tabs-wrapper {
    margin-top: 50px;
}

.fc__tabs-wrapper .tabs {
    /* width: 80%; */
    margin: auto;
}

.fc__tabs-wrapper .tab-list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    /* border: 1px solid #ccc; */
}

.fc__tabs-wrapper .tab {
    cursor: pointer;
    text-align: center;
    width: 100%;
    padding: 10px 40px;
    border: 1px solid var(--primary-color);
    transition: background-color 0.7s ease, color 0.7s ease;
}

.fc__tabs-wrapper .tab:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.fc__tabs-wrapper .tab.active {
    background: var(--primary-color);
    color: var(--white-color) !important;
    /* border-bottom: 2px solid red; */
}

.fc__tabs-wrapper .tab-content {
    margin-top: 20px;
    text-align: left;
}

.fc__tabs-wrapper .advantages__p {
    display: flex;
    align-items: center;
    /* text-transform: capitalize; */
}

.fc__tabs-wrapper .advantages__p svg {
    width: 2em;
    height: 2em;
}

.fc__tabs-wrapper .advantages__span {
    color: var(--primary-color);
    font-weight: 600;
}

.content_cable_wires {
    text-transform: uppercase;
}

.wires__list--wrapper ul {
    list-style: square;
}

.wires__list--wrapper ul li {
    margin-bottom: 8px;
}

.fcsubinside__img {
    width: 35%;
}

/* Table */
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
}

th {
    text-align: center !important;
}

th[colspan="8"] {
    /* background-color: var(--primary-color);
    color: white; */
    text-align: center;
}

th {
    /* background-color: #f2f2f2; */
}

th[rowspan="2"] {
    vertical-align: middle;
    text-align: center;
}

thead tr:nth-child(2) th {
    /* background-color: var(--primary-color); */
    /* color: white; */
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: var(--primary-color) !important;
    border-width: 1px !important;
}

.line {
    width: 41%;
    height: 1px;
    background-color: var(--primary-color);
}

td p{
    margin-bottom: 0px;
    margin-left: 6px;
    text-align: left;
}
/* End Table */

/* Wires */
.wires__p svg {
    width: 2em;
    height: 2em;
}

.wires__p span {
    color: var(--primary-color);
    font-weight: 600;
}

.switch-img {
    transition: opacity 0.7s ease-in-out;
    /* Example transition, adjust as needed */
}

.switch__btn--main_wrapper {
    display: flex;
    gap: 10px;
}

.switch__btn__wrapper {
    border: 1px solid var(--black-color);
    width: fit-content;
    cursor: pointer;
}

.switch__btn {
    width: 30px;
    height: 30px;
    background: var(--black-color);
    margin: 2px;
}

.switch__btn__wrapper_1 {
    border: 1px solid #f1453e;
    cursor: pointer;
}

.switch__btn_1 {
    width: 30px;
    height: 30px;
    background: #f1453e;
    margin: 2px;
}

.switch__btn__wrapper_2 {
    border: 1px solid #ffe807;
    cursor: pointer;
}

.switch__btn_2 {
    width: 30px;
    height: 30px;
    background: #ffe807;
    margin: 2px;
}

.switch__btn__wrapper_3 {
    border: 1px solid #005ac8;
    cursor: pointer;
}

.switch__btn_3 {
    width: 30px;
    height: 30px;
    background: #005ac8;
    margin: 2px;
}

.switch__btn__wrapper_4 {
    border: 1px solid brown;
    cursor: pointer;
}

.switch__btn_4 {
    width: 30px;
    height: 30px;
    background: brown;
    margin: 2px;
}

@media (max-width: 576px) {
    .fcsc__wrapper {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .fc__tabs-wrapper .tab {
        padding: 8px 20px;
    }

    .fcsubinside__img {
        width: 50%;
    }

    .fc__tabs-wrapper .tab-content {
        width: 100%;
        overflow-x: scroll;
    }

    .fc__tabs-wrapper .tab-list {
        flex-wrap: wrap;
    }

    .fcsc__wrapper .row {
        row-gap: 30px;
    }

    /* Table */
    table {
        font-size: 12px;
    }

    th,
    td {
        padding: 4px;
    }

    .line {
        width: 70%;
    }

    /* End Table */
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .fcsc__wrapper {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .fc__tabs-wrapper .tab {
        padding: 9px 30px;
    }

    .fcsubinside__img {
        width: 45%;
    }

    .fc__tabs-wrapper .tab-list {
        flex-wrap: wrap;
    }

    .fcsc__wrapper .row {
        row-gap: 30px;
    }

    /* Table */
    table {
        font-size: 13px;
    }

    th,
    td {
        padding: 6px;
    }

    .line {
        width: 60%;
    }

    /* End Table */
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .fcsc__wrapper {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .fc__tabs-wrapper .tab {
        padding: 10px 40px;
    }

    .fcsubinside__img {
        width: 40%;
    }

    /* Table */
    table {
        font-size: 14px;
    }

    th,
    td {
        padding: 8px;
    }

    .line {
        width: 50%;
    }

    /* End Table */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .fcsc__wrapper {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .fc__tabs-wrapper .tab {
        padding: 12px 50px;
    }

    .fcsubinside__img {
        width: 35%;
    }

    /* Table */
    table {
        font-size: 15px;
    }

    th,
    td {
        padding: 10px;
    }

    .line {
        width: 41%;
    }

    /* End Table */
}