.homeaboutus__main-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
}

.homeaboutus__title {
    font-size: 35px;
    font-weight: 600;
    padding-bottom: 10px;
}

.homeaboutus__des {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 20px;
}

/* Button */
.homeaboutus__btn {
    border-radius: 8px;
    background-color: var(--primary-color);
    border: none;
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 16px;
    width: 220px;
    transition: all 0.5s;
    cursor: pointer;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, .7);
}

.homeaboutus__btn {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.homeaboutus__btn:after {
    content: '»';
    position: absolute;
    opacity: 0;
    /* top: 14px; */
    right: -20px;
    transition: 0.5s;
}

.homeaboutus__btn:hover {
    padding-right: 24px;
    padding-left: 8px;
}

.homeaboutus__btn:hover:after {
    opacity: 1;
    right: 10px;
}

@media (max-width: 576px) {
    .homeaboutus__main-wrapper {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .homeaboutus__title {
        font-size: 20px;
    }

    .homeaboutus__des {
        font-size: 12px;
    }

    .homeaboutus__btn {
        font-size: 12px;
        padding: 8px;
        width: 140px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .homeaboutus__main-wrapper {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .homeaboutus__title {
        font-size: 24px;
    }

    .homeaboutus__des {
        font-size: 12px;
    }

    .homeaboutus__btn {
        font-size: 14px;
        padding: 10px;
        width: 160px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .homeaboutus__title {
        font-size: 28px;
    }

    .homeaboutus__des {
        font-size: 14px;
    }

    .homeaboutus__btn {
        font-size: 16px;
        padding: 12px;
        width: 180px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .homeaboutus__title {
        font-size: 30px;
    }

    .homeaboutus__des {
        font-size: 14px;
    }

    .homeaboutus__btn {
        font-size: 18px;
        padding: 14px;
        width: 200px;
    }
}