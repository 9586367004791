@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body{
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  position: relative;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

a{
  text-decoration: none !important;
}

::selection{
  background: var(--primary-color);
  color: var(--white-color);
}

@media (min-width: 576px) {
  .container {
    max-width: 650px !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px !important;
  }
}