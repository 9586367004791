.contact_bg_wrapper {
    position: relative;
    padding: 80px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--primary-color-50);
    /* background-image: url('../../assets/images/contact/contact_1.png'); */
    /* z-index: -1; */
}

.contact_background {
    width: 100%;
}

.contact__list_-location__wrapper {
    padding-bottom: 50px;
}

.map_frame {
    height: 600px;
}

/* Form */
.contactform__wrpper {
    border-radius: 30px;
    margin: 50px 0px;
    position: relative;
}

.getintouch_title {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    padding-bottom: 40px;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: var(--primary-color);
}

.form__filled-wrapper {
    margin-bottom: 20px;
}

.contactform__wrpper .contact--ctm__input {
    padding: 15px 20px;
    background-color: var(--primary-color-50);
    border-width: 0px 0px 0px 0px;
    border-radius: 4px 4px 4px 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Adding box-shadow */
    width: 100%;
}


.contactform__wrpper .contact--ctm__input:focus-visible {
    border: none !important;
    outline: none !important;
}

.get_in--touchform {
    background: var(--white-color);
    padding: 60px !important;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

/* Change background and text color on hover */
.contact--ctm__select {
    height: 54px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Adding box-shadow */
}

.contact--ctm__select:focus-visible {
    outline: none !important;
    border: none !important;
}

/* select option:focus{
    background-color: red;
} */
.con__title {
    font-weight: 500;
    font-size: 20px;
    line-height: normal;
    font-family: var(--font-family);
    margin-bottom: 6px;
    color: var(--white-color);
}

.connect_btn {
    background: none !important;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    font-weight: 500;
    font-size: 18px;
    overflow: hidden;
    padding: 8px 24px;
    position: relative;
    transition: all .35s;
    z-index: 1;
}

.connect_btn::before{
    opacity: .5;
}

.connect_btn::before,
.connect_btn::after {
    background: var(--primary-color);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: -100%;
    transition: all .35s;
    width: 100%;
    z-index: -1;
}

.connect_btn::after {
    transition-delay: .2s;
}

.connect_btn:hover {
    color: white; /* Text color on hover */
}
.connect_btn:hover::before, .connect_btn:hover::after {
    top: 0;
}
.contact--des {
    font-weight: 300;
    font-size: 25px;
    max-width: 450px;
    font-family: 'Poppins', sans-serif;
    color: var(--black-color);
    margin-top: 20px;
}

.error_message {
    color: red;
}

/* Contactinfolist */

.contact__infolist--wrapper {
    margin: 100px 0px 50px 0px;
    position: relative;
}

.contactinfolist__wrap {
    background: var(--primary-color-50);
    border: 1px solid var(--primary-color-50);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 12px;
    padding: 20px;
    width: 100%;
    text-align: center;
}

.contactinfolist__Des_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contactlist__icon-wrapper {
    background: var(--primary-color);
    color: var(--white-color);
    border-radius: 50%;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactinfolist__title {
    font-family: var(--font-family);
    font-size: 20px;
    color: var(--primary-color);
    padding-top: 8px;
    padding-bottom: 2px;
    font-weight: 900;
}

.contactinfolist__sub-title {
    font-size: 16px;
    font-weight: 400;
    color: var(--black-color);
    padding-bottom: 15px;
}

.contactinfolist__des {
    font-size: 18px;
    font-weight: 600;
    color: var(--black-color);
    border-top: 1px solid var(--primary-color);
    padding-top: 20px;
}

.contactinfolist__des a {
    color: inherit;
    transition: color 0.5s ease;
}

.contactinfolist__des:hover a {
    color: var(--primary-color);
}

.contactstart_chat {
    padding-top: 8px;
}

.contactus__btn_start {
    border-radius: 8px;
    background-color: var(--primary-color);
    border: none;
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 6px 16px;
    width: 80%;
    transition: all 0.5s;
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.contactus__btn_start span {
    padding-right: 8px;
    transition: padding-right 0.5s;
}

.contactus__btn_start svg {
    transition: transform 0.5s;
}

.contactus__btn_start:hover span {
    padding-right: 16px;
}

.contactus__btn_start:hover svg {
    transform: translateX(8px);
}

/* End Contact info list */

@media (max-width: 576px) {

    /* Contact  */
    .map_frame {
        height: 300px;
    }

    /* End contact */

    .contact__infolist--wrapper {
        margin: 80px 0px 30px 0px;
    }

    .contact__infolist--wrapper .row {
        gap: 30px;
    }

    .contactinfolist__title {
        font-size: 18px;
        padding-top: 6px;
        padding-bottom: 0px;
    }

    .contactinfolist__sub-title {
        font-size: 14px;
        padding-bottom: 10px;
    }

    .contactinfolist__des {
        font-size: 16px;
        padding-top: 15px;
    }

    .contactus__btn_start {
        font-size: 18px;
        padding: 5px 12px;
    }

    /* Get in touch */
    .contactform__wrpper {
        padding: 10px;
        /* margin: 30px 0; */
    }

    .getintouch_title {
        font-size: 30px;
        padding-bottom: 20px;
    }

    .get_in--touchform {
        padding: 20px !important;
    }

    /* End Get in touch */
}

/* Responsive styles for screens between 576px and 767.98px */
@media (min-width: 576px) and (max-width: 767.98px) {

    /* Contact  */
    .map_frame {
        height: 400px;
    }

    /* End contact */
    .contact__infolist--wrapper {
        margin: 90px 0px 40px 0px;
    }

    .contact__infolist--wrapper .row {
        gap: 30px;
    }

    .contactinfolist__title {
        font-size: 20px;
        padding-top: 7px;
        padding-bottom: 1px;
    }

    .contactinfolist__sub-title {
        font-size: 15px;
        padding-bottom: 12px;
    }

    .contactinfolist__des {
        font-size: 17px;
        padding-top: 18px;
    }

    .contactus__btn_start {
        font-size: 19px;
        padding: 5px 14px;
    }

    /* Get in touch */
    .contactform__wrpper {
        padding: 30px;
    }

    .get_in--touchform {
        padding: 30px !important;
    }

    .getintouch_title {
        font-size: 35px;
    }

    /* End Get in touch */
}

/* Responsive styles for screens between 768px and 991.98px */
@media (min-width: 768px) and (max-width: 991.98px) {

    /* Contact  */
    .map_frame {
        height: 500px;
    }

    /* End contact */
    .contact__infolist--wrapper {
        margin: 95px 0px 45px 0px;
    }

    .contact__infolist--wrapper .row {
        justify-content: center;
        row-gap: 30px;
    }

    .contactinfolist__title {
        font-size: 22px;
        padding-top: 9px;
        padding-bottom: 3px;
    }

    .contactinfolist__sub-title {
        font-size: 16px;
        padding-bottom: 14px;
    }

    .contactinfolist__des {
        font-size: 19px;
        padding-top: 21px;
    }

    .contactus__btn_start {
        font-size: 21px;
        padding: 6px 15px;
    }

    /* Get in touch */
    .get_in--touchform {
        padding: 40px !important;
    }

    .getintouch_title {
        font-size: 40px;
    }

    /* End Get in touch */
}

/* Responsive styles for screens between 992px and 1199.98px */
@media (min-width: 992px) and (max-width: 1199.98px) {

    /* Contact  */
    .map_frame {
        height: 550px;
    }

    /* End contact */
    .contact__infolist--wrapper {
        margin: 100px 0px 50px 0px;
    }

    .contactinfolist__title {
        font-size: 24px;
        padding-top: 10px;
        padding-bottom: 4px;
    }

    .contactinfolist__sub-title {
        font-size: 17px;
        padding-bottom: 16px;
    }

    .contactinfolist__des {
        font-size: 20px;
        padding-top: 23px;
    }

    .contactus__btn_start {
        font-size: 22px;
        padding: 6px 16px;
    }

    /* Get in touch */
    .getintouch_title {
        font-size: 43px;
    }

    /* End Get in touch */
}