.authorizedbywrapper__main-wrapper{
    padding-top: 50px;
    padding-bottom: 50px;
}

.authorizedby_title{
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: var(--primary-color);
}

.authorizedimg_wrappper{
    display: flex;
    gap: 50px;
    justify-content: center;
    flex-wrap: wrap;
}

.author{
    border: 1px solid var(--white-light-color);
    border-radius: 12px;
    padding: 20px;
    transition: border 0.7s ease;
}

.author:hover{
    border: 1px solid var(--primary-color);
}

@media (max-width: 576px) {
    .authorizedbywrapper__main-wrapper {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .authorizedby_title {
        font-size: 30px;
    }

    .authorizedimg_wrappper {
        gap: 30px;
    }
    .author {
        padding: 15px;
    }
}

/* Responsive styles for screens between 576px and 767.98px */
@media (min-width: 576px) and (max-width: 767.98px) {
    .authorizedbywrapper__main-wrapper {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .authorizedby_title {
        font-size: 35px;
    }

    .authorizedimg_wrappper{
        gap: 35px;
    }

    .author {
        padding: 18px;
    }
}

/* Responsive styles for screens between 768px and 991.98px */
@media (min-width: 768px) and (max-width: 991.98px) {
    .authorizedbywrapper__main-wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .authorizedby_title {
        font-size: 40px;
    }

    .authorizedimg_wrappper{
        gap: 40px;
    }

    .author {
        padding: 20px;
    }
}

/* Responsive styles for screens between 992px and 1199.98px */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .authorizedbywrapper__main-wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .authorizedby_title {
        font-size: 43px;
    }

    .authorizedimg_wrappper{
        gap: 45px;
    }

    .author {
        padding: 20px;
    }
}