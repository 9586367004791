.truston__main-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
}

.truston_title {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 60px;
    text-align: center;
}

.truston_title::after {
    content: "";
    border-bottom: 3px solid var(--primary-color);
    width: 45%;
    display: block;
    padding-top: 8px;
    margin: auto;
}

.feature__wrapper {
    position: relative;
}

.circle {
    width: 100px;
    height: 100px;
    background-color: var(--primary-color);
    border: 5px solid var(--white-color);
    border-radius: 50%;
    position: absolute;
    top: -46px;
    left: calc(50% - 46px);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.3);
    clip-path: inset(0 0 -50% -50%);
    transition: background-color 0.7s, border 0.7s;
}

.feature__wrapper:hover .circle {
    background-color: var(--white-color);
    border: 5px solid var(--primary-color);
}

.feature__wrapper .circle svg {
    height: 60px;
}

.feature__wrapper .circle svg .cls-1 {
    fill: var(--white-color);
    transition: fill 0.5s ease;
}

.feature__wrapper:hover .circle svg .cls-1 {
    fill: var(--primary-color);
}

.rectangle {
    width: 100%;
    height: 220px;
    padding: 55px 20px 20px 20px;
    background-color: var(--white-color);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    border-radius: 10px;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.7s;
}

.feature__wrapper:hover .rectangle {
    background-color: var(--primary-color);
}

.rectangle p {
    color: var(--primary-color);
    font-size: 24px;
    text-align: center;
    margin: 0;
}

.feature__wrapper:hover .rectangle p {
    color: var(--white-color);
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 576px) {
    .truston__main-wrapper {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .truston_title {
        font-size: 30px;
        margin-bottom: 40px;
    }

    .circle {
        width: 80px;
        height: 80px;
        top: -36px;
        left: calc(50% - 36px);
    }

    .circle svg {
        height: 50px;
    }

    .rectangle {
        height: 180px;
        padding: 45px 15px 15px 15px;
    }

    .rectangle p {
        font-size: 20px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .truston__main-wrapper {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .truston_title {
        font-size: 35px;
        margin-bottom: 50px;
    }

    .circle {
        width: 90px;
        height: 90px;
        top: -41px;
        left: calc(50% - 41px);
    }

    .circle svg {
        height: 55px;
    }

    .rectangle {
        height: 200px;
        padding: 50px 18px 18px 18px;
    }

    .rectangle p {
        font-size: 22px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .truston__main-wrapper {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .truston_title {
        font-size: 40px;
        margin-bottom: 55px;
    }

    .circle {
        width: 95px;
        height: 95px;
        top: -43px;
        left: calc(50% - 43px);
    }

    .circle svg {
        height: 58px;
    }

    .rectangle {
        height: 210px;
        padding: 52px 19px 19px 19px;
    }

    .rectangle p {
        font-size: 23px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .truston__main-wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .truston_title {
        font-size: 43px;
        margin-bottom: 60px;
    }

    .circle {
        width: 100px;
        height: 100px;
        top: -46px;
        left: calc(50% - 46px);
    }

    .circle svg {
        height: 60px;
    }

    .rectangle {
        height: 220px;
        padding: 55px 20px 20px 20px;
    }

    .rectangle p {
        font-size: 24px;
    }
}