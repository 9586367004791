.certificate_bg_wrapper {
    position: relative;
    padding: 80px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background: var(--primary-color-50);
}

.certificate__main-wrapper{
    padding-top: 50px;
    padding-bottom: 50px;
}

.certificate__wrappper{
    border: 3px solid var(--primary-color);
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Certificate__name{
    padding-top: 20px;
    text-align: center;
    color: var(--primary-color);
    font-weight: bold;
    font-size: 18px;
}

.certificate_btn{
    background: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
    margin-top: 10px;
}

.certificate__wrappper .iso__log0{
    padding: 20px;
}

.certificate_btn {
    position: relative;
    overflow: hidden;
    transition: transform 0.2s;
}

.certificate_btn:hover {
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.certifictate_close_btn{
    background: var(--primary-color) !important;
    border: none !important;
}

.certificate__main-wrapper .row{
    row-gap: 30px;
}