.footer__main-wrapper {
    background-color: var(--black-color);
    padding-top: 50px;
}

.footer__row {
    padding-bottom: 50px;
}

.footer_title {
    color: var(--white-color);
    text-transform: capitalize;
    letter-spacing: 0px;
    font-size: 22px;
    margin-bottom: 32px;
    position: relative;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 20px;
    text-decoration-color: var(--primary-color);
}

.footer__main-wrapper ul {
    padding-left: 0px;
}

.footer__main-wrapper li {
    margin-bottom: 8px;
}

.footer__quicklink--a {
    color: rgb(200 200 200 / 87%);
    font-size: 14px;
    font-family: var(--font-family);
    font-weight: 400;
    font-style: normal;
    transition: all .5s;
}

.footer__quicklink--a:hover {
    padding-left: 5px;
    color: var(--primary-color);
}

/* Copyright */

.copyright__footer_main-wrapper {
    border-top: 1px solid rgb(200 200 200 / 20%);
    padding-top: 28px;
    padding-bottom: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copyright_text {
    color: rgb(200 200 200 / 87%);
    transition: all .5s;
}

.social__media-wrapper{
    display: flex;
    align-items: center;
    gap: 12px;
}

.facebook__logo {
    transition: all .5s;
    height: 25px;
    width: 25px;
}

.facebook__logo:hover {
    fill: var(--primary-color);
}

.instagram__logo{
    height: 30px;
    width: 30px;
}

@media (max-width: 576px) {
    .footer__main-wrapper {
        padding-top: 30px;
    }

    .footer__row {
        padding-bottom: 30px;
        row-gap: 30px;
    }

    .footer_title {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .footer__quicklink--a {
        font-size: 12px;
    }

    .copyright__footer_main-wrapper {
        flex-direction: column;
        text-align: center;
        gap: 10px;
    }

    .social__media-wrapper {
        margin-top: 10px;
    }
}

/* Responsive styles for screens between 576px and 767.98px */
@media (min-width: 576px) and (max-width: 767.98px) {
    .footer__main-wrapper {
        padding-top: 40px;
    }

    .footer__row {
        padding-bottom: 40px;
        row-gap: 30px;
    }

    .footer_title {
        font-size: 20px;
        margin-bottom: 25px;
    }

    .footer__quicklink--a {
        font-size: 13px;
    }

    .copyright__footer_main-wrapper {
        flex-direction: column;
        text-align: center;
    }

    .social__media-wrapper {
        margin-top: 10px;
    }

    iframe{
        height: 250px;
    }
}

/* Responsive styles for screens between 768px and 991.98px */
@media (min-width: 768px) and (max-width: 991.98px) {
    .footer__main-wrapper {
        padding-top: 45px;
    }

    .footer__row {
        padding-bottom: 45px;
        row-gap: 30px;
    }

    .footer_title {
        font-size: 21px;
        margin-bottom: 28px;
    }

    .footer__quicklink--a {
        font-size: 13px;
    }

    .copyright__footer_main-wrapper {
        flex-direction: row;
        text-align: left;
    }

    .social__media-wrapper {
        margin-top: 0;
    }
}

/* Responsive styles for screens between 992px and 1199.98px */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .footer__main-wrapper {
        padding-top: 50px;
    }

    .footer__row {
        padding-bottom: 50px;
    }

    .footer_title {
        font-size: 22px;
        margin-bottom: 32px;
    }

    .footer__quicklink--a {
        font-size: 14px;
    }

    .copyright__footer_main-wrapper {
        flex-direction: row;
        text-align: left;
    }

    .social__media-wrapper {
        margin-top: 0;
    }
}