.ctm__header {
    background: var(--white-color);
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.ctm__header .header__logo {
    width: 80%;
}

.scroll_sticky_box-shadow {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.7rem !important;
        padding-left: 0.7rem !important;
    }
}

/* Header menu */
.menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.menu>li {
    float: left;
    position: relative;
}

.menu>li>a {
    display: block;
    color: var(--black-color);
    text-align: center;
    padding: 14px 10px;
    text-decoration: none;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    transition: color 0.5s ease;
}

.menu>li>a:hover {
    color: var(--primary-color);
}

.submenu {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    background-color: var(--white-color);
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999999;
    top: 100%;
    left: 0;
    border-radius: 3px;
    padding-left: 0px !important;
    transition: opacity 0.5s ease;
}

.submenu>li {
    position: relative;
    border-bottom: 1px solid #f9f9f9;
}

.submenu>li>a {
    color: var(--black-color);
    padding: 6px 18px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.submenu>li>a:hover {
    background-color: var(--primary-color);
    color: var(--white-color) !important;
}

.menu>li:hover>.submenu,
.submenu>li:hover>.submenu {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease, visibility 0s;
}

.submenu>li:hover>.submenu {
    top: 0;
    left: 100%;
    min-width: 200px;
}

.home-page-menu .menu__a {
    color: var(--primary-color) !important;
    text-decoration: underline !important;
    text-underline-offset: 12px;
}

.active {
    color: var(--primary-color) !important;
}

/* End header menu */

/* Responsive start */
@media (max-width: 768px) {
    /* Header logo */
    .ctm__header .header__logo{
        width: 60%;
    }

    .navbar-toggler{
        padding: 0 !important;
        border: none !important;
    }
    .navbar-toggler:focus{
        box-shadow: none !important;
    }
    /* End Header Logo */
    .offcanvas.offcanvas-end {
        width: 250px !important;
    }

    .offcanvas-body {
        padding: 0 !important;
    }

    .menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .submenu {
        display: none;
        /* Initially hide all submenus */
    }

    .submenu-open {
        display: block;
        /* Show submenu when the class is applied */
    }

    .menu>li {
        float: none;
        /* Stack menu items vertically */
        border-bottom: 1px solid rgb(231, 231, 231);
        width: 100%;
    }

    .menu>li>a {
        text-align: left;
        /* Align text to the left */
        padding: 10px 14px;
        /* Adjust padding */
    }

    .submenu {
        position: static;
        /* Position submenu items statically */
        box-shadow: none;
        /* Remove box-shadow */
    }

    .submenu>li {
        border-bottom: none;
        /* Remove bottom border */
    }

    .submenu>li>a {
        padding: 10px 14px;
        /* Adjust padding */
    }

    .submenu-open .wires_Submenu a,
    .submenu-open .cables_submenu a {
        color: var(--black-color) !important;
    }

    .menu>li:hover>.submenu,
    .submenu>li:hover>.submenu {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s ease, visibility 0s;
    }

    .submenu>li:hover>.submenu {
        top: 0;
        left: 100%;
        min-width: 200px;
    }

    .home-page-menu .menu__a {
        text-decoration: none !important;
    }

    .chevron-rotated {
        transform: rotate(180deg);
        /* Rotate icon 180 degrees */
        transition: transform 0.3s ease;
        /* Smooth transition */
    }

    .ch-rotate,
    .che-rotate {
        transform: rotate(90deg);
        transition: transform 0.3s ease;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .menu>li>a{
        padding: 10px;
        font-size: 14px;
    }
}


/* End Responive start */