.Hero__slider--mainwrapper .slick-track {
    overflow-x: hidden;
}

.slider__img {
    width: 100%;
}

.slick-prev:before,
.slick-next:before {
    font-size: 20px;
    color: white;
}

.slick-prev,
.slick-next {
    width: 50px;
    height: 50px;
    z-index: 1;
}

.slick-prev:before,
.slick-next::before {
    content: '';
}

.slick-prev {
    left: -60px;
}

.slick-next {
    right: -60px;
}

@media (max-width: 1024px) {
    .slick-prev,
    .slick-next {
        display: none !important;
    }
}